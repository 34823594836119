import '../layout/index.scss'

import {loadBlocksFromGraph} from 'blocks/blocks_graph'
import {graphql} from 'gatsby'
import {Job} from 'layout/pages/job'
import React from 'react'
import {JobQueryQuery} from 'types/graphtypes'
import {getImageFromField, getWysiwygFromField} from 'util/drupal'
import {TemplateType} from 'types/basictypes'
import {Template} from 'layout/templates/template'
import {previewFragmentToPreviewItem} from 'layout/preview/preview_graph'
import Helmet from 'react-helmet'
import node from 'node-html-parser/dist/nodes/node'

const JobPage: React.FC<TemplateType<JobQueryQuery>> = ({
	data,
	pageContext
}) => {
	const {
		page: {
			field_date,
			field_expiration_date,
			field_remote_region,
			field_is_part_time,
			field_short_description,
			field_applylink,
			body,
			path: { langcode },
			fields: {
				metaFragment,
				previewFragment
			},
			relationships: {
				field_blocks,
				field_job_title,
				field_region,
				field_type,
				field_header_image,
				field_employment_type,
				field_address_details,
			}
		},
		allPagePreview: { relatedFragments },
		allNodeJob: { relatedJobsFragments },
		site: {
			siteMetadata: {siteUrl}
		}
	} = data

	const preview = previewFragmentToPreviewItem(previewFragment)
	const blocks = loadBlocksFromGraph(field_blocks)
	const image = getImageFromField(field_header_image)
	const description = getWysiwygFromField(field_short_description)
	const region = field_region[0] ? field_region[0].name : ''
	const type = field_type[0] ? field_type[0].name : ''

	const postingMeta: any = {
		'@context': 'https://schema.org/',
		'@type': 'JobPosting',
		title: [preview.title, ...field_job_title.map(tax => tax.name)],
		datePosted: field_date,
		validThrough: field_expiration_date,
		jobLocation: field_address_details.map(details => ({
			'@type': 'Place',
			address: {
				'@type': 'PostalAddress',
				streetAddress: details.field_address,
				postalCode: details.field_postal,
				addressLocality: details.field_city,
				addressRegion: details.field_state,
				addressCountry: details.relationships?.field_country?.name
			}
		})),
		description: body?.value,
		hiringOrganization: {
			'@type': 'Organization',
			'name': 'imec',
			'sameAs': siteUrl,
			'logo': siteUrl + '/imec-logo.png'
		},
		employmentType: [
			field_is_part_time ? 'PART_TIME' : 'FULL_TIME',
			...field_employment_type.map(tax => {
				switch(tax.name.toLowerCase()){
					case 'full-time': return 'FULL_TIME'
					case 'part-time': return 'PART_TIME'
					case 'contractor': return 'CONTRACTOR'
					case 'master projects/internships':
					case 'bachelor projects':
					case 'bachelor projects/internships':
						return 'INTERN'
					default:
						return 'TEMPORARY'
				}
			})
		],
		url: siteUrl + pageContext.slug
	}
	if(field_remote_region){
		postingMeta.applicantLocationRequirements = {
			'@type': 'Country',
			name: field_remote_region
		}
		postingMeta.jobLocationType = 'TELECOMMUTE'
	}

	return (
		<Template
			preview={preview}
			pageContext={pageContext}
			lang={langcode}
			relatedFragments={relatedFragments}
			relatedJobsFragments={relatedJobsFragments}
			content={blocks}
			metaFragment={metaFragment}
		>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(postingMeta)}
				</script>
			</Helmet>
			<Job
				header_image={image}
				title={preview.title}
				date={field_date}
				short_description={description}
				applylink={field_applylink}
				body={body?.value}
				location={region}
				category={type}
				blocks={blocks}
			/>
		</Template>
	)
}

export default JobPage

export const pageQuery = graphql`
	query JobQuery($slug: String!, $allTags: [String]) {
		page: nodeJob(fields: {slug: {eq: $slug}}) {
			field_date(formatString: "YYYY-MM-DD")
			field_expiration_date(formatString: "YYYY-MM-DD")
			field_remote_region
			field_is_part_time
			field_short_description {
				processed
			}
			field_applylink
			body {
				value
			}
			path {
				langcode
			}
			fields {
				metaFragment: meta {...Meta}
				previewFragment: preview {...Preview}
			}
			relationships {
				field_job_title {name}
				field_type {name}
				field_region {name}
				field_employment_type {name}
				field_header_image {
					...ImageMeta
					relationships {
						field_media_image {
							localFile {
								image: childImageSharp {
									fluid(maxWidth: 1200) {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
					}
				}
        field_address_details {
          relationships {
            field_country {
              name
            }
          }
          field_state
          field_postal
          field_city
          field_address
        }
			}
		}

		allPagePreview(
			limit: 13
			sort: {fields: date, order: DESC}
			filter: {tags: {in: $allTags}, has_image: {eq: true}}
		) {
			relatedFragments: nodes {
				...Preview
			}
		}

		allNodeJob(
			filter: {
				field_job_status: {eq: true}
				relationships: {field_tags: {elemMatch: {name: {in: $allTags}}}}
			}
			limit: 4
			sort: {fields: field_date, order: DESC}
		) {
			relatedJobsFragments: nodes {
				...JobPreview
			}
		}
		
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`
